<template>
  <section class="head">
    <div class="content">
      <div class="top">
        <h1>{{ projectData.taskName }}&nbsp;</h1>
        <p class="time">
          <ClockCircleOutlined />
          {{ dateFormat(projectData.startTime) }} ~
          {{ dateFormat(projectData.endTime) }}
        </p>
        <p class="complet" v-if="completCondition.length">
          <!-- 通过条件 -->
          {{ $t("Pub_Lab_ThroughCondition") }}：<span
            class="tag"
            v-for="(item, index) in completCondition"
            :key="index"
          >
            {{ index + 1 }}.{{ item }}
          </span>
        </p>
        <!-- <p class="intro" v-if="projectData.intro">{{ projectData.intro }}</p> -->
      </div>
      <div class="bottom">
        <div class="person" v-if="projectData.createUser">
          <div class="avatar">
            <img :src="projectData.portrait" alt="avatar" />
          </div>
          <div>
            <div class="name">
              <OpenData type="userName" :openid="projectData.createUser" />
            </div>
            <div class="label">{{ $t("project.assign_personnel") }}</div>
            <!-- 指派人 -->
          </div>
        </div>
        <div class="classify">
          <template v-for="item in resourceTypes" :key="item.id">
            <div class="item" v-if="item.extraArr.length">
              <div class="label">{{ item.name }}</div>
              <div class="value">
                <strong>{{ item.extraArr.length }}</strong>
                {{ item.unit }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="step">
    <div class="progress"></div>
    <div class="next">继续学习</div>
  </section> -->

  <a-spin :spinning="loadding">
    <section class="mian">
      <div class="stage" v-if="stagesData.length > 1">
        <a-anchor wrapperClass="stage-menu" :affix="false">
          <a-anchor-link
            v-for="(item, index) in stagesData"
            :key="item.stageId"
            :href="'#stage' + (index + 1)"
          >
            <template v-slot:title>
              <!-- 阶段 -->
              <span class="name"
                >{{ $t("project.stages", [index + 1]) }}
                {{ item.stageName }}</span
              >
              <span class="num">{{ item.details.length || 0 }}</span>
            </template>
          </a-anchor-link>
        </a-anchor>
      </div>
      <div class="detail" :class="{ full: stagesData.length == 1 }">
        <a-tabs v-model:activeKey="activeKey">
          <!-- 详情介绍 -->
          <a-tab-pane key="1" :tab="$t('project.details')">
            <div style="padding: 14px 30px 10px 30px">
              <div
                v-if="projectData.intro"
                class="v-html"
                v-html="projectData.intro"
              ></div>
              <div style="padding: 40px 0" v-else>
                <a-empty :description="$t('LB_NoIntroduction')" />
                <!-- 暂无介绍 -->
              </div>
            </div>
          </a-tab-pane>
          <!-- 培训目录 -->
          <a-tab-pane key="2" :tab="$t('project.catalog')">
            <div class="directory" v-if="stagesData.length">
              <div
                v-for="(stage, index) in stagesData"
                :key="stage.stageId"
                :id="'stage' + (index + 1)"
              >
                <div class="title">
                  <div class="headline">
                    <h5>
                      <!-- <span>阶段{{ index + 1 }}</span> -->
                      {{ stagesData[index].stageName }}
                    </h5>
                    <span class="schedule">
                      {{ stagesData[index].progress }}%
                    </span>
                  </div>
                  <div class="intro" v-if="stagesData[index].intro">
                    {{ stagesData[index].intro }}
                  </div>
                </div>
                <ul class="list">
                  <li
                    v-for="item in stagesData[index].details"
                    :key="item.detailId"
                    :class="{ lock: !item.learnFlag }"
                    @click="jump(item, stagesData[index].stageId)"
                  >
                    <div class="item-box">
                      <div class="unfold" v-if="item.resourceType == 8">
                        <PlusSquareOutlined
                          style="font-size: 15px; padding-top: 2px"
                          v-show="!item.timeListShow"
                        />
                        <MinusSquareOutlined
                          style="font-size: 15px; padding-top: 2px"
                          v-show="item.timeListShow"
                        />
                      </div>
                      <div class="tag-box">
                        <span
                          class="tag float"
                          v-if="
                            item.resourceType == 4 ||
                            item.resourceType == 5 ||
                            item.resourceType == 7
                          "
                        >
                          {{ resourceType[item.resourceType - 1].name }}
                        </span>
                        <span class="tag" v-else>
                          {{ resourceType[item.resourceType - 1].name }}
                        </span>
                      </div>
                      <div class="info">
                        <h5 v-if="item.resourceType != 8">
                          {{ item.detailName }}
                        </h5>
                        <h5 v-else>
                          <span class="names">
                            <template
                              v-for="(local, localIndex) in item.localList"
                              :key="localIndex"
                            >
                              {{ local.detailName }}
                              <template
                                v-if="localIndex != item.localList.length - 1"
                                >，</template
                              >
                            </template>
                          </span>
                          <span class="total"
                            >（{{
                              $t("project.total_of", [item.localList.length])
                            }}）</span
                          >
                          <!-- 共 场 -->
                        </h5>
                        <div class="bottom">
                          <template v-if="item.resourceType == 3">
                            <span>
                              <!-- 时间 -->
                              {{ $t("Pub_Tab_Time") }}：{{
                                item.exam.examTime[0].substring(0, 16)
                              }}
                              -
                              {{ item.exam.examTime[1].substring(0, 16) }}
                            </span>
                          </template>
                          <template v-else-if="item.resourceType == 8">
                            <span>
                              {{ dateFormat(item.train.startTime) }}
                              -
                              {{ dateFormat(item.train.endTime) }}
                            </span>
                          </template>
                          <template v-else-if="item.resourceType == 9">
                            <span>
                              <!-- 时间 -->
                              {{ $t("Pub_Tab_Time") }}：{{
                                dateFormat(item.live.startTime)
                              }}
                              -
                              {{ dateFormat(item.live.endTime) }}
                            </span>
                          </template>
                          <template v-else-if="item.resourceType == 5 && item.startTime">
                            <span>
                              <!-- 时间 -->
                              {{ $t("Pub_Tab_Time") }}：{{
                                dateFormat(item.startTime)
                              }}
                              -
                              {{ dateFormat(item.endTime) }}
                            </span>
                          </template>
                          <template v-else>
                            <span>
                              <!-- 时间 -->
                              {{ $t("Pub_Tab_Time") }}：{{
                                dateFormat(projectData.startTime)
                              }}
                              -
                              {{ dateFormat(projectData.endTime) }}
                            </span>
                          </template>
                        </div>
                        <div class="bottom">
                          <span
                            v-if="
                              item.resourceType == 3 &&
                              projectData.examPass == 2
                            "
                          >
                            {{ $t("project.require") }}：{{
                              $t("project.passing_the_exam")
                            }}
                            <!-- 要求：考试成绩及格后才可过关 -->
                          </span>
                        </div>
                      </div>
                      <div class="status" v-if="item.resourceType == 8">
                        <template v-if="item.learnFlag">
                          <span
                            style="color: #2cbc15"
                            v-if="
                              item.progress == 100 &&
                              (projectData.examPass == 1 ||
                                (projectData.examPass == 2 &&
                                  item.qualifier != 2))
                            "
                          >
                            <img src="@/assets/image/kczj-dui.png" alt="" />
                            {{ $t("CM_Completed") }}
                            <!-- 已完成 -->
                          </span>
                        </template>
                        <span style="color: #999" v-else>
                          {{ $t("not_unlocked") }}
                        </span>
                        <!-- 未解锁 -->
                      </div>
                      <div class="status" v-else>
                        <template v-if="item.learnFlag">
                          <span
                            style="color: #2cbc15"
                            v-if="
                              item.progress == 100 &&
                              (projectData.examPass == 1 ||
                                (projectData.examPass == 2 &&
                                  item.qualifier != 2))
                            "
                          >
                            <img src="@/assets/image/kczj-dui.png" alt="" />
                            {{ $t("CM_Completed") }}
                            <!-- 已完成 -->
                          </span>
                          <span
                            style="color: #ff6200"
                            v-else-if="item.waitMarking == 1"
                          >
                            {{ $t("XB_Exam_PendingApproval") }}
                            <!-- 待批阅 -->
                          </span>
                          <span
                            style="color: #666"
                            v-else-if="
                              item.progress == 0 && item.qualifier == 0
                            "
                          >
                            {{ $t("Pub_Tab_NotStart") }}
                            <!-- 未开始 -->
                          </span>
                          <span
                            style="color: #dd6161"
                            v-else-if="
                              item.resourceType == 49
                                ? item.qualifier == 2
                                : projectData.examPass == 2 &&
                                  item.qualifier == 2
                            "
                          >
                            {{ $t("XB_Unthread") }}
                            <!-- 不通过 -->
                          </span>
                          <span style="color: #3996fe" v-else>
                            {{ $t("XB_UnderWay") }}
                          </span>
                          <!-- 进行中 -->
                        </template>
                        <span style="color: #999" v-else>
                          {{ $t("not_unlocked") }}
                        </span>
                        <!-- 未解锁 -->
                      </div>
                      <div
                        v-if="item.resourceType == 3"
                        :class="[
                          'exam-status',
                          `exam-status-${item.qualifier}`,
                        ]"
                      ></div>
                    </div>
                    <div
                      class="local-box"
                      v-if="item.resourceType == 8"
                      v-show="item.timeListShow"
                      @click.stop
                    >
                      <div
                        class="day-item"
                        v-for="(day, dayIndex) in item.timeList"
                        :key="dayIndex"
                      >
                        <h3 class="day">{{ day.date }}</h3>
                        <div
                          class="hour-item"
                          :class="{ ed: hour.isEd }"
                          v-for="(hour, hourIndex) in day.list"
                          :key="hourIndex"
                          @click="localJump(hour.data)"
                        >
                          <div class="hour-times">
                            <div class="hour-time">
                              {{ hour.startTime.slice(11, 16) }} -
                              {{ hour.endTime.slice(11, 16) }}
                            </div>
                            <div class="hour-duration">
                              {{
                                hour.duration.h
                                  ? hour.duration.h + $t("CM_Hour")
                                  : ""
                              }}{{
                                hour.duration.m
                                  ? hour.duration.m + $t("XB_Min")
                                  : ""
                              }}
                            </div>
                          </div>
                          <div class="hour-title">
                            <span class="hour-tag">{{ hour.typeName }}</span>
                            <p v-if="hour.type == 2">
                              <a-tooltip>
                                <template #title>{{
                                  hour.data.detailName
                                }}</template>
                                {{ hour.data.detailName }}
                              </a-tooltip>
                            </p>
                            <p v-else>{{ $t("train.attendance") }}</p>
                            <!-- 考勤 -->
                          </div>
                          <div class="hour-info">
                            <p v-if="hour.type == 2">
                              <EnvironmentOutlined />
                              <span class="span">
                                {{ hour.data.train.address }}
                              </span>
                              <span class="span">
                                <!-- 讲师： -->
                                {{ $t("XB_Course_Lecturer") }}：<OpenData
                                  type="userName"
                                  :openid="hour.data.train.lecturerName"
                                  v-if="hour.data.train.lecturerType == 1"
                                /><span v-else>{{
                                  hour.data.train.lecturerName
                                }}</span>
                              </span>
                            </p>
                            <p v-else>
                              <!-- 考勤负责人： -->
                              {{ $t("train.head") }}：<OpenData
                                type="userName"
                                :openid="hour.data.train.leaderUser"
                              />
                            </p>
                          </div>
                          <div class="hour-status">
                            <p v-if="hour.type == 1">
                              <span
                                style="color: #2cbc15"
                                v-if="hour.data.train.inTime"
                              >
                                <img src="@/assets/image/kczj-dui.png" alt="" />
                                {{ $t("Pub_Btn_AlreadySign") }}
                                <!-- 已签到 -->
                              </span>
                              <span v-else>{{ $t("train.no_sign") }}</span>
                              <!-- 未签到 -->
                            </p>
                            <p v-else-if="hour.type == 3">
                              <span
                                style="color: #2cbc15"
                                v-if="hour.data.train.outTime"
                              >
                                <img src="@/assets/image/kczj-dui.png" alt="" />
                                {{ $t("train.signed_out") }}
                                <!-- 已签退 -->
                              </span>
                              <span v-else>{{ $t("train.no_sign_out") }}</span>
                              <!-- 未签退 -->
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </a-tab-pane>
          <!-- 学习评论 -->
          <a-tab-pane
            v-if="hasComment"
            key="3"
            :tab="$t('project.comments') + '（' + commentParams.Total + '）'"
            forceRender
          >
            <div style="padding: 14px 30px 10px 30px">
              <my-comment
                v-if="commentParams.resourceId > 0"
                :CommentParams="commentParams"
              ></my-comment>
            </div>
          </a-tab-pane>
          <!-- 业务账号 -->
          <a-tab-pane
              key="4"
              :tab="$t('project.business_account')"
              v-if="isOpenDistributeAccount && (projectData.isOpenDistributeAccount ===1)"
              forceRender
          >
            <div style="padding: 14px 30px 30px 30px;">
              <businessAccountDetail :data="accountLists" v-if="projectData.qualifier ===1"></businessAccountDetail>
              <a-empty style="padding: 60px 0" v-else
                       :image="require('@/assets/image/businessAccountEmpty.png')"
                       :image-style="{ height: '145px' }"
              >
                <template #description>
                  <span>
                    {{$t('project.noLearning_tip')}}
                  </span>
                </template>
              </a-empty>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </section>
  </a-spin>

  <homework ref="homeworkRef" @updata:homework="homeworkChange" />
  <a-modal v-model:visible="isNeedToNotify" width="800px" closable :title="$t('project.business_account')+ '-' + projectData.taskName">
    <div style="height: 350px;overflow-y: auto;">
      <businessAccountDetail :data="accountLists" v-if="isNeedToNotify && projectData.qualifier ===1"></businessAccountDetail>
      <a-empty style="padding: 60px 0" v-else
               :image="require('@/assets/image/businessAccountEmpty.png')"
               :image-style="{ height: '145px' }"
        >
        <template #description>
                  <span class="emptyTip">
                    {{$t('project.noLearning_tip')}}
                  </span>
        </template>
      </a-empty>
    </div>
    <template #footer>
        <a-button style="display: block; margin: 0 auto;" key="submit" type="primary" @click="accountNoticeFn">{{$t('Pub_Close')}}</a-button>
    </template>
  </a-modal>

</template>

<script>
import { useI18n } from "vue-i18n";
import {ref, getCurrentInstance, watch, onMounted} from "vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";
import { dateFormat } from "@/utils/tools";
import { resourceType } from "@/utils/business";
import { projectDetail, projectAccountList, accountNotice } from "@/api/project";
import myComment from "@/components/my-comment";
import OpenData from "@/components/OpenData.vue";
import homework from "@/components/homework/index.vue";
import businessAccountDetail from "@/components/businessAccountDetail.vue";
export default {
  components: {
    "my-comment": myComment,
    OpenData,
    homework,
    businessAccountDetail,
  },
  setup()
  {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const taskId = parseInt(route.query.id || 0);
    let loadding = ref(true),
        hasComment = ref(false),
        isOpenDistributeAccount = ref(false),
        activeKey = ref("2"),
        projectData = ref({}),
        stagesData = ref([]),
        completCondition = ref([]),
        activeStage = ref(0),
        resourceTypes = ref([]),
        commentParams = ref({
          page: 1,
          pageSize: 10,
          resourceId: taskId || 0,
          type: 2, //0知识 1课程 2任务 3新闻
          taskType: 34, //任务类型参数 34培训项目 35培训包 37新员工 38课程专题
          Total: 0,
        }),
        accountLists =ref([]),
        isNeedToNotify =ref(false)
    ;

    watch(
        () => store.getters.companyInfo,
        (company) => {
          hasComment.value = company.useComment == 1;
          isOpenDistributeAccount.value = company.isOpenDistributeAccount == 1;
        },
        { immediate: true }
    );

    const getDetail = () => {
      projectDetail(taskId).then((res) => {
        loadding.value = false;
        if (res.ret === 0) {
          let d = res.data,
              nowTime = new Date().getTime();
          d.remainderDay = Math.floor((d.endTime * 1000 - nowTime) / 86400000);
          d.remainderHours = Math.floor((d.endTime * 1000 - nowTime) / 3600000);
          d.collaboratorArr = JSON.parse(d.collaborator);
          resourceTypes.value = JSON.parse(JSON.stringify(resourceType));
          if (d.stages.length && d.stages[0].details)
            d.stages[0].checked = true;
          for (let i = 0; i < d.stages.length; i++) {
            let firstLocal = null;
            //项目未逾期  -- 逾期后可学 isOverdue 1 可学
            if ((d.complete != 3 && d.remainderDay >= 0) || d.isOverdue === 1) {
              //阶段是否可学
              if (d.learnOrder == 2) {
                d.stages[i].learnFlag = true;
              } else {
                if (i == 0) {
                  d.stages[i].learnFlag = true;
                } else {
                  if (d.stages[i - 1].progress == 100) {
                    d.stages[i].learnFlag = true;
                  }
                }
              }
              d.stages[i].details = d.stages[i].details || [];
              for (var j = 0; j < d.stages[i].details.length; j++) {
                resourceTypes.value[
                d.stages[i].details[j].resourceType - 1
                    ].extraArr.push(d.stages[i].details[j]);
                //阶段明细是否可学
                if (d.stages[i].learnFlag) {
                  if (d.stages[i].learnOrder == 2) {
                    d.stages[i].details[j].learnFlag = true;
                  } else {
                    if (j == 0) {
                      d.stages[i].details[j].learnFlag = true;
                    } else {
                      if (d.stages[i].details[j - 1].progress == 100) {
                        d.stages[i].details[j].learnFlag = true;
                      }
                    }
                  }
                }
                if (d.stages[i].details[j].resourceType == 8) {
                  if (firstLocal == null) {
                    firstLocal = j;
                    d.stages[i].details[firstLocal].localList = [];
                  }
                  d.stages[i].details[firstLocal].localList.push(
                      JSON.parse(JSON.stringify(d.stages[i].details[j]))
                  );
                }
              }
            } else {
              //阶段是否可学
              if (d.stages[i].progress == 100) {
                d.stages[i].learnFlag = true;
              } else {
                if (i == 0) {
                  d.stages[i].learnFlag = true;
                }
              }
              d.stages[i].details = d.stages[i].details || [];
              for (var j = 0; j < d.stages[i].details.length; j++) {
                resourceTypes.value[
                d.stages[i].details[j].resourceType - 1
                    ].extraArr.push(d.stages[i].details[j]);
                //阶段明细是否可学
                if (d.stages[i].details[j].progress == 100) {
                  d.stages[i].details[j].learnFlag = true;
                }
                if (d.stages[i].details[j].resourceType == 8) {
                  if (firstLocal == null) {
                    firstLocal = j;
                    d.stages[i].details[firstLocal].localList = [];
                  }
                  d.stages[i].details[firstLocal].localList.push(
                      JSON.parse(JSON.stringify(d.stages[i].details[j]))
                  );
                }
              }
            }
            if (firstLocal != null) {
              let details = d.stages[i].details;
              for (let index = 0; index < details.length; index++) {
                const detail = details[index];
                if (detail.resourceType == 8 && index != firstLocal) {
                  details.splice(index, 1);
                  index--;
                }
              }
              let times = [];
              let now = new Date();
              details[firstLocal].timeList = [];
              details[firstLocal].localList.forEach((item) => {
                if (
                    item.train.inStartTime != "" &&
                    item.train.inEndTime != "" &&
                    item.train.inStartTime != "1970-01-01 08:00:00" &&
                    item.train.inEndTime != "1970-01-01 08:00:00"
                ) {
                  times.push({
                    data: item,
                    startTime: item.train.inStartTime,
                    endTime: item.train.inEndTime,
                    duration: getDuration(
                        item.train.inEndTime,
                        item.train.inStartTime
                    ),
                    type: 1,
                    typeName: $t("Lab_LessonDetail_T_SignIn"),
                    // 签到
                    isEd: new Date(item.train.inStartTime) <= now,
                  });
                }
                let s = dateFormat(item.train.startTime, "YYYY-MM-DD HH:mm:ss"),
                    e = dateFormat(item.train.endTime, "YYYY-MM-DD HH:mm:ss");
                times.push({
                  data: item,
                  startTime: s,
                  endTime: e,
                  duration: getDuration(e, s),
                  type: 2,
                  typeName: $t("Pub_Tab_CT"),
                  // 面授
                  isEd: new Date(s) <= now,
                });
                if (
                    item.train.outStartTime != "" &&
                    item.train.outEndTime != "" &&
                    item.train.outStartTime != "1970-01-01 08:00:00" &&
                    item.train.outEndTime != "1970-01-01 08:00:00"
                ) {
                  times.push({
                    data: item,
                    startTime: item.train.outStartTime,
                    endTime: item.train.outEndTime,
                    duration: getDuration(
                        item.train.outEndTime,
                        item.train.outStartTime
                    ),
                    type: 3,
                    typeName: $t("Lab_LessonDetail_T_SignOut"),
                    // 签退
                    isEd: new Date(item.train.outStartTime) <= now,
                  });
                }
              });
              times.sort((a, b) => (b.startTime < a.startTime ? 1 : -1));
              let date = "",
                  list = [];
              times.forEach((item, index) => {
                let day = item.startTime.slice(0, 10);
                if (index == 0) {
                  date = day;
                }
                if (day == date) {
                  list.push(item);
                  if (index == times.length - 1) {
                    if (list.length) {
                      details[firstLocal].timeList.push({
                        date,
                        list,
                      });
                    }
                  }
                } else {
                  if (list.length) {
                    details[firstLocal].timeList.push({
                      date,
                      list,
                    });
                  }
                  date = day;
                  list = [item];
                  if (index == times.length - 1) {
                    if (list.length) {
                      details[firstLocal].timeList.push({
                        date,
                        list,
                      });
                    }
                  }
                }
              });
            }
          }
          projectData.value = d;
          stagesData.value = d.stages || [];
          completCondition.value = [];
          if (projectData.value.learnPass > 0) {
            completCondition.value.push(
                $t("project.progress_needs", [projectData.value.learnPass])
            );
            // 培训进度需要达到
          }
          if (projectData.value.examNum > 0) {
            completCondition.value.push(
                $t("project.exams_required_pass", [projectData.value.examNum])
            );
            // 考试需要及格 场
          }

        } else {
          router.replace({
            path: `/error`,
            query: {
              title: $t("project.not_exist"),
              // 抱歉，您访问的培训项目不存在。
              path: "/project",
              name: $t("MN_TrainingProject_MHome"),
              // 培训项目
            },
          });
        }
      }).then(()=>{
        if(projectData.value.qualifier ===1){
          getAccountList()
        }
      });
    };
    const getAccountList = () => {
      projectAccountList({taskId}).then((res) => {
        if (res.ret === 0){
          let d = res.data
          isNeedToNotify.value = d.isNeedToNotify
          accountLists.value = d.accountList
        }
      });
    };
    const accountNoticeFn = () => {
      accountNotice({taskId}).then((res) => {
        isNeedToNotify.value = false
      });
    };
    const getDuration = (date1, date2) => {
      let timestamp = 0;
      timestamp = (new Date(date1) - new Date(date2)) / 1000;
      let timesJson = timeTransition(timestamp);
      return {
        h: timesJson.h,
        m: timesJson.m,
      };
    };

    // 时长转换
    const timeTransition = (val) => {
      if (val < 60) {
        return {
          h: 0,
          m: 0,
          s: val,
        };
      } else {
        let m = Math.floor(val / 60); // 分钟
        let s = Math.floor(val % 60); // 余秒
        if (m < 60) {
          return {
            h: 0,
            m: m,
            s: s,
          };
        } else {
          let h = Math.floor(m / 60); // 小时数
          let m2 = Math.floor(m % 60); // 余分钟
          return {
            h: h,
            m: m2,
            s: s,
          };
        }
      }
    };

    const receiveMessage = (e) => {
      if (e.origin !== location.origin) return;
      if (e.data == "reload-project") {
        getDetail();
      }
    };

    window.addEventListener("message", receiveMessage, false);

    onBeforeRouteLeave((to, from, next) => {
      window.removeEventListener("message", receiveMessage, false);
      next();
    });

    const homeworkRef = ref(null);
    const currHomework = ref({});
    let urlStr = "";
    const jump = (item, sid) => {
      let currentTime = Date.parse(new Date()) / 1000;
      if (projectData.value.startTime > currentTime) {
        proxy.$message.warning($t("project.cannot_study"));
        // 项目未开始不能学习
        return false;
      }
      if (item.learnFlag) {
        switch (item.resourceType) {
          case 1:
            urlStr = `/doc/detail?taskId=${taskId}&sid=${sid}&did=${item.detailId}`;
            if (store.getters.companyInfo.useNewWindow == 1) {
              window.open(urlStr + "&ddtab=true");
            } else {
              window.location.href = urlStr;
            }
            break;
          case 2:
            urlStr = `/course/detail?taskId=${taskId}&sid=${sid}&did=${item.detailId}`;
            if (store.getters.companyInfo.useNewWindow == 1) {
              window.open(urlStr + "&ddtab=true");
            } else {
              window.location.href = urlStr;
            }
            break;
          case 3:
            router.push({
              path: "/exam/detail",
              query: {
                id: taskId,
                did: item.detailId,
              },
            });
            break;
          case 4:
            router.push({
              path: "/questionnaire/detail",
              query: {
                source: "project",
                id: taskId,
                did: item.detailId,
                resourceId: item.resourceId,
                progress: item.progress,
              },
            });
            break;
          case 5:
            if (item.startTime > currentTime) {
              // 作业未开始
              proxy.$message.warning($t("homework.NotStarted"));
            } else if (projectData.value.isOverdue !== 1 && currentTime > item.endTime) {
              // 作业已结束
              proxy.$message.warning($t('homework.HasEnd'));
            } else {
              currHomework.value = item;
              homeworkRef.value.openModal({
                id: taskId,
                did: item.detailId,
              });
            }
            break;
          case 7:
            router.push({
              path: "/questionnaire/detail",
              query: {
                source: "project",
                id: taskId,
                did: item.detailId,
                resourceId: item.resourceId,
                progress: item.progress,
              },
            });
            break;
          case 8:
            item.timeListShow = !item.timeListShow;
            break;
          case 9:
            router.push({
              path: "/live/detail",
              query: {
                id: taskId,
                did: item.detailId,
              },
            });
            break;
          case 49:
            proxy.$message.warning($t("AI_Practice_tips"));
            break;
        }
      } else {
        if (
            projectData.value.complete == 3 ||
            projectData.value.remainderDay < 0
        ) {
          let errMsg =  projectData.value.isOverdue === 1 ?  $t("not_unlocked"): $t("project.project_overdue")
          proxy.$message.warning(errMsg);
          // 项目已逾期  或者 未解锁
        } else {
          proxy.$message.warning($t("not_unlocked"));
          // 未解锁
        }
      }
    };

    const localJump = (item) => {
      let currentTime = Date.parse(new Date()) / 1000;
      if (projectData.value.startTime > currentTime) {
        proxy.$message.warning($t("project.cannot_study"));
        // 项目未开始不能学习
        return false;
      }
      router.push({
        path: "/train/detail",
        query: {
          id: taskId,
          did: item.detailId,
        },
      });
    };

    const homeworkChange = (status) => {
      getDetail();
    };
    onMounted(() => {
     getDetail()
    });
    return {
      dateFormat,
      resourceType,
      loadding,
      hasComment,
      isOpenDistributeAccount,
      projectData,
      stagesData,
      completCondition,
      activeStage,
      resourceTypes,
      commentParams,
      accountLists,
      isNeedToNotify,
      accountNoticeFn,
      jump,
      localJump,
      activeKey,
      homeworkRef,
      homeworkChange,
    };
  }
,
};
</script>

<style lang="less">
.stage-menu {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  margin-top: 24px;
  position: sticky;
  top: 24px;
  .ant-anchor-ink {
    &::before {
      width: 0;
      height: 0;
    }
    .ant-anchor-ink-ball {
      display: none !important;
      opacity: 0 !important;
    }
  }
  .ant-anchor-link {
    padding: 0;
    margin-bottom: 12px;
    &-title {
      display: block;
      height: 64px;
      .mixinFlex(space-between; center);
      background-color: #eeeeee;
      padding: 12px 0 12px 24px;
      cursor: pointer;
      border-radius: 6px;
      transition: all 0.2s;
      .name {
        font-size: 16px;
        width: calc(100% - 54px);
        margin: 0;
        .mixinEllipsis(40px);
        transition: all 0.2s;
      }
      .num {
        font-size: 16px;
        width: 44px;
        border-left: 1px solid #666666;
        padding-left: 8px;
        line-height: 0.8;
        transition: all 0.2s;
        font-family: DINAlternate-Bold, DINAlternate;
      }
    }
    &.ant-anchor-link-active,
    &:hover {
      .ant-anchor-link-title {
        background-color: @color-theme;
        .name {
          color: #fff;
        }
        .num {
          color: #fff;
          border-left-color: #fff;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="less" scoped>
.head {
  // height: 392px;
  background-image: url("../../assets/image/project_detail_banner.png");
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  padding-top: 86px;
  .content {
    .mixinWrap();
    .top {
      // height: 136px;
      padding-bottom: 10px;
      border-left: 10px solid #fff;
      color: #fff;
      padding-left: 18px;
      line-height: 1.5;
      h1 {
        color: #fff;
        font-size: 32px;
        margin: 0;
        .mixinEllipsis(48px);
      }
      .time {
        font-size: 20px;
        margin: 2px 0 0 0;
        .mixinFlex(flex-start; center);
        font-family: PingFangSC-Regular, PingFang SC;
        span {
          margin-right: 8px;
        }
      }
      .complet {
        font-size: 14px;
        margin: 8px 0 0 0;
        span {
          padding-right: 12px;
        }
      }
      .intro {
        font-size: 14px;
        margin: 8px 0 0 0;
        // .mixinEllipsis(42px; 2);
      }
    }
    .bottom {
      .mixinFlex(space-between; center);
      color: #fff;
      margin-top: 10px;
      .person {
        .mixinFlex(space-between; center);
        .avatar {
          .mixinImgWrap(40px; 40px);
          border-radius: 50%;
          margin-right: 12px;
        }
        .name {
          font-size: 18px;
        }
        .label {
          font-size: 12px;
          color: #ddd;
        }
      }
      .classify {
        .mixinFlex(space-between; center);
        .item {
          font-size: 12px;
          padding: 0 28px;
          position: relative;
          .value {
            strong {
              font-size: 40px;
              font-weight: normal;
              margin-right: 10px;
            }
          }
          &::after {
            content: "";
            width: 2px;
            height: 10px;
            background-color: #fff;
            position: absolute;
            top: 16px;
            right: -1px;
          }
          &:last-child {
            padding-right: 0;
            &::after {
              width: 0;
              height: 0;
            }
          }
        }
      }
    }
  }
}

.step {
  .mixinWrap();
  height: 120px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: -60px;
  padding: 30px;
  .mixinFlex(space-between; center);
  .next {
    width: 160px;
    height: 50px;
    background-color: #e63450;
    border-radius: 6px;
    font-size: 22px;
    color: #fff;
    .mixinFlex(center; center);
    cursor: pointer;
  }
}

.mian {
  padding-bottom: 64px;
  .mixinWrap();
  .mixinFlex(space-between);
  .stage {
    width: 282px;
    .name {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .detail {
    width: 894px;
    background-color: #fff;
    border-radius: 6px;
    margin-top: 24px;
    ::v-deep(.ant-tabs-nav-wrap) {
      padding: 0 30px;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab) {
      padding: 16px 0px;
      font-size: 16px;
      color: #999;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
      color: @color-theme;
    }
    ::v-deep(.ant-tabs-ink-bar) {
      background-color: @color-theme;
    }
    &.full {
      width: 100%;
    }
    .directory {
      padding: 14px 30px 10px 30px;
      .title {
        background-color: #f5f5f6;
        padding: 20px 24px;
        .headline {
          .mixinFlex(space-between; center);
          h5 {
            font-size: 16px;
            margin: 0;
            line-height: 1.5;
            width: calc(100% - 50px);
            span {
              padding-right: 16px;
            }
          }
          .schedule {
            font-size: 18px;
            font-family: DINAlternate-Bold, DINAlternate;
          }
        }
        .intro {
          margin-top: 6px;
          font-size: 14px;
        }
      }
      .list {
        li {
          border-bottom: 1px solid #eaf0ee;
          .item-box {
            .mixinFlex(space-between);
            padding: 22px 20px 22px 30px;
            cursor: pointer;
            position: relative;
            .unfold {
              position: absolute;
              left: 15px;
            }
            .tag-box {
              width: 100px;
              position: relative;
              .tag {
                width: max-content;
                height: 22px;
                padding: 0 4px;
                border: 1px solid @color-theme;
                color: @color-theme;
                font-size: 14px;
                border-radius: 2px;
                .mixinFlex(center; center);
              }
              .float {
                position: absolute;
                left: 0;
                top: 0;
              }
            }
            .info {
              width: calc(100% - 200px);
              h5 {
                font-size: 16px;
                line-height: 22px;
                .names {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  word-break: break-all;
                  display: inline-block;
                  max-width: 90%;
                }
                .total {
                  color: @color-theme;
                  vertical-align: top;
                }
              }
              .bottom {
                color: #666;
                font-size: 14px;
              }
            }
            .status {
              font-size: 14px;
              width: 100px;
              text-align: right;
              img {
                width: 15px;
                height: 13px;
                vertical-align: -1px;
              }
            }
            &.lock {
              // .noevents();
              .tag {
                border: 1px solid #999;
                color: #999;
              }
              .info {
                h5 {
                  color: #999;
                }
                .bottom {
                  color: #999;
                }
              }
            }
          }
          .local-box {
            background-color: #f5f5f6;
            padding: 24px;
            margin-bottom: 22px;
            .day-item {
              margin-bottom: 4px;
              .day {
                font-size: 16px;
                margin-bottom: 16px;
              }
              .hour-item {
                padding-bottom: 16px;
                .mixinFlex(space-between);
                position: relative;
                cursor: pointer;
                &::before {
                  content: "";
                  width: 11px;
                  height: 11px;
                  border-radius: 50%;
                  background-color: #999;
                  border: 2px solid #f5f5f6;
                  position: absolute;
                  top: 6px;
                  left: 115px;
                  z-index: 2;
                }
                &.ed {
                  &::before {
                    content: "";
                    width: 13px;
                    height: 13px;
                    background-color: #fff;
                    border: 4px solid @color-theme;
                    top: 5px;
                    left: 114px;
                    box-shadow: 0 0 2px 1px @color-theme;
                  }
                }
                &::after {
                  content: "";
                  width: 1px;
                  background-color: #999;
                  position: absolute;
                  top: 0;
                  left: 120px;
                  bottom: 0;
                  z-index: 1;
                }
                &:nth-child(2) {
                  &::after {
                    top: 6px;
                  }
                }
                &:last-child {
                  &::after {
                    bottom: 46px;
                  }
                }
                .hour-times {
                  .hour-time {
                    font-size: 14px;
                    margin-bottom: 5px;
                  }
                  .hour-duration {
                    font-size: 12px;
                    color: #999;
                    text-align: right;
                  }
                }
                .hour-title {
                  width: calc(100% - 640px);
                  margin-left: 60px;
                  .mixinFlex();
                  .hour-tag {
                    width: 100px;
                    height: 22px;
                    border: 1px solid @color-theme;
                    color: @color-theme;
                    font-size: 14px;
                    border-radius: 2px;
                    .mixinFlex(center; center);
                  }
                  p {
                    width: calc(100% - 40px);
                    margin: 0;
                    padding-left: 14px;
                    font-size: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                  }
                }
                .hour-info {
                  width: 400px;
                  color: #666;
                  .mixinFlex();
                  p {
                    margin: 0;
                    padding-left: 14px;
                    font-size: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    .span {
                      padding-right: 14px;
                    }
                  }
                }
                .hour-status {
                  width: 80px;
                  text-align: right;
                  color: #666;
                }
              }
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.exam-status {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
}
.exam-status-1 {
  background: url("~@/assets/image/exam-pass.png") no-repeat;
  background-size: 100% 100%;
}
.exam-status-2 {
  background: url("~@/assets/image/exam-nopass.png") no-repeat;
  background-size: 100% 100%;
}
.exam-status-3 {
  background: url("~@/assets/image/exam-referee.png") no-repeat;
  background-size: 100% 100%;
}
.emptyTip{
  font-size: 14px;
  color: #999999;
}

</style>
